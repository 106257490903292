import DynamicComponent from "../components/DynamicBlock";
import Header from "../components/Header";
import Footer from "../components/Footer";

import { useStoryblok } from "../lib/storyblok";
import { getStaticProps, StoryProvider } from "./[...slug]";

export default function Home({ story: storyProp, ...props }) {
  const story = useStoryblok(storyProp, true);

  return (
    <StoryProvider {...props} story={story}>
      <Header />
      <DynamicComponent blok={story.content} />
      <Footer />
    </StoryProvider>
  );
}

export { getStaticProps };
